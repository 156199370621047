import {
  CalendarIcon,
  CompanyIcon,
  Event,
  GeolocationIcon,
  PeopleIcon,
  TChallengePeriod,
  TChallengeType,
  useUnits,
} from '@geovelo-frontends/commons';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Skeleton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import daysImage from '../assets/img/challenges/days.svg';
import workImage from '../assets/img/challenges/work.svg';

import { Button } from './button';
import Data from './data';

export const defaultChallengesImages: { [key in TChallengeType]: string } = {
  cyclingDays: daysImage,
  traveledDistance: workImage,
  homeWorkJourneysCount: workImage,
  journeysCount: workImage,
};

export function EventCard({
  period,
  event,
}: {
  event?: Event;
  period: TChallengePeriod;
}): JSX.Element {
  const { pathname, search } = useLocation();
  const { t } = useTranslation();
  const { formatNumber, toDistance } = useUnits();

  return (
    <Card
      sx={{ borderRadius: 4, display: 'flex', flexDirection: 'column', height: '100%' }}
      variant="outlined"
    >
      <Box flexShrink={0} overflow="hidden" sx={{ aspectRatio: 2.2 }}>
        {event ? (
          <CardMedia
            alt={event.title}
            component="img"
            height="100%"
            image={
              event.challengeTemplate?.photo ||
              defaultChallengesImages[event.challengeTemplate?.type || 'traveledDistance']
            }
            width="100%"
          />
        ) : (
          <Skeleton height="100%" variant="rectangular" width="100%" />
        )}
      </Box>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: 2 }}>
        <Box display="flex" flexDirection="column" flexGrow={1} gap={2}>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography fontSize="1.15rem" fontWeight={700}>
              {event ? event.title : <Skeleton variant="text" width="90%" />}
            </Typography>
            {period === 'past' && (
              <Typography>
                {event ? (
                  t('commons.periods.from_to', {
                    from: event.startDate.format('L'),
                    to: event.endDate.format('L'),
                  })
                ) : (
                  <Skeleton variant="text" width="50%" />
                )}
              </Typography>
            )}
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            {period !== 'past' && (
              <DataRow
                items={[
                  {
                    key: 'dates',
                    fullWidth: true,
                    element: (
                      <Data
                        fullWidth
                        Icon={CalendarIcon}
                        value={
                          event &&
                          t('commons.periods.from_to', {
                            from: event.startDate.format('L'),
                            to: event.endDate.format('L'),
                          })
                        }
                      />
                    ),
                  },
                ]}
              />
            )}
            {period !== 'future' && (
              <DataRow
                items={[
                  {
                    key: 'groups',
                    element: (
                      <Data
                        fullWidth
                        Icon={event?.isOnlyForCompanies ? CompanyIcon : PeopleIcon}
                        value={
                          event
                            ? t('events.details.participants', {
                                context: event.isOnlyForCompanies ? 'companies' : '',
                                count: event.numberOfParticipants,
                              })
                            : undefined
                        }
                      />
                    ),
                  },
                  {
                    key: 'progress',
                    element: (
                      <Data
                        fullWidth
                        Icon={GeolocationIcon}
                        value={
                          event?.challengeTemplate
                            ? event.challengeTemplate.type === 'cyclingDays'
                              ? t('commons.stats.active_days', { count: event.sumOfProgressValues })
                              : event.challengeTemplate.type === 'traveledDistance'
                                ? t('commons.stats.distance', {
                                    count: Math.floor(event.sumOfProgressValues / 1000),
                                    distance: toDistance(event.sumOfProgressValues),
                                  })
                                : t('commons.stats.journeys', {
                                    count: event.sumOfProgressValues,
                                    countFormatted: formatNumber(event.sumOfProgressValues),
                                  })
                            : undefined
                        }
                      />
                    ),
                  },
                ]}
              />
            )}
          </Box>
          {(!event || event.challengeTemplate?.description) && (
            <Typography
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '3',
              }}
              variant="body2"
            >
              {event ? (
                event.challengeTemplate?.description
              ) : (
                <>
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="90%" />
                </>
              )}
            </Typography>
          )}
        </Box>
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: 2,
          padding: 2,
          rowGap: 1,
          '&& > div': { marginLeft: 0 },
        }}
      >
        <Box flexGrow={1}>
          {event ? (
            <Button
              fullWidth
              color="primary"
              component={Link}
              state={{ backPath: `${pathname}${search}` }}
              to={`/${event.id}`}
              variant={period === 'past' ? 'contained' : 'outlined'}
            >
              {t('events.list.actions.see', {
                context:
                  period === 'current'
                    ? 'leaderboard'
                    : period === 'future'
                      ? 'details'
                      : 'results',
              })}
            </Button>
          ) : (
            <Skeleton height={36.5} variant="rounded" width="100%" />
          )}
        </Box>
      </CardActions>
    </Card>
  );
}

function DataRow({
  items,
}: {
  items: Array<{ fullWidth?: boolean; key: string; element: JSX.Element }>;
}): JSX.Element {
  return (
    <Box alignItems="center" columnGap={2} display="flex" flexWrap="wrap" rowGap={1}>
      {items.map(({ key, fullWidth, element }) => (
        <Box key={key} width={{ xs: '100%', sm: fullWidth ? '100%' : 'calc((100% - 16px) / 2)' }}>
          {element}
        </Box>
      ))}
    </Box>
  );
}
