import isPropValid from '@emotion/is-prop-valid';
import {
  AppService,
  AuthService,
  GeogroupService,
  User,
  UserGeogroup,
} from '@geovelo-frontends/commons';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'moment/locale/fr';
import { Font } from '@react-pdf/renderer';
import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { HelmetProvider, HelmetServerState } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';

import NunitoBold from '../assets/fonts/NunitoSans_10pt_SemiCondensed-Black.ttf';
import NunitoItalic from '../assets/fonts/NunitoSans_7pt_SemiCondensed-Italic.ttf';
import NunitoRegular from '../assets/fonts/NunitoSans_7pt_SemiCondensed-Medium.ttf';
import { environment } from '../environment';

import { AppContext } from './context';
import i18n from './i18n';
import Router from './router';
import theme from './theme';
import './tracking';

Font.register({
  family: 'Nunito',
  fonts: [
    { src: NunitoRegular, fontWeight: 400 },
    { src: NunitoItalic, fontWeight: 400, fontStyle: 'italic' },
    { src: NunitoBold, fontWeight: 700 },
  ],
});

const helmetContext = {} as {
  helmet: HelmetServerState;
};

function App(): JSX.Element {
  const [initialized, setInitialized] = useState(false);

  const [transparentBackgroundEnabled, toggleTransparentBackground] = useState(false);
  const [footerDisplayed, displayFooter] = useState(true);

  const [currentUser, setCurrentUser] = useState<User | null>();
  const [currentUserGeogroups, setCurrentUserGeogroups] = useState<UserGeogroup[]>();
  const [currentUserCompanyGroups, setCurrentUserCompanyGroups] = useState<UserGeogroup[]>();
  const [signingOut, setSigningOut] = useState(false);

  useEffect(() => {
    AppService.environment = environment;
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (currentUser) getCurrentUserGeogroups();

    return () => {
      setCurrentUserGeogroups(undefined);
      setCurrentUserCompanyGroups(undefined);
    };
  }, [currentUser]);

  async function getCurrentUserGeogroups() {
    try {
      setCurrentUserGeogroups(undefined);
      setCurrentUserCompanyGroups(undefined);

      const geogroups = await GeogroupService.getUserGeogroups({
        statuses: ['MANUALLY_JOIN', 'AUTOMATICALLY_JOIN'],
      });

      const companyGroups = geogroups.filter(({ group }) => group.type === 'company');

      const companySubGroups = (
        await Promise.all(
          companyGroups.map(({ group: { id: parentId } }) =>
            GeogroupService.getUserGeogroups({
              parentId,
              statuses: ['MANUALLY_JOIN', 'AUTOMATICALLY_JOIN'],
            }),
          ),
        )
      ).flatMap((groups) => groups);

      setCurrentUserGeogroups(geogroups.filter(({ group: { code } }) => code !== 'france') || []);
      setCurrentUserCompanyGroups([...companyGroups, ...companySubGroups]);
    } catch (err) {
      console.error(err);
    }
  }

  async function signOut() {
    setSigningOut(true);

    try {
      await AuthService.signOut();
      setCurrentUser(null);
    } catch (err) {
      console.error(err);
    }

    setSigningOut(false);
  }

  if (!initialized) return <></>;

  return (
    <AppContext.Provider
      value={{
        app: { transparentBackgroundEnabled, footerDisplayed },
        user: {
          current: currentUser,
          geogroups: currentUserGeogroups,
          companyGroups: currentUserCompanyGroups,
          signingOut,
        },
        actions: {
          toggleTransparentBackground,
          displayFooter,
          setCurrentUser,
          getCurrentUserGeogroups,
          signOut,
        },
      }}
    >
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <StyleSheetManager
            shouldForwardProp={(propName, elementToBeRendered) =>
              typeof elementToBeRendered === 'string' ? isPropValid(propName) : true
            }
          >
            <CssBaseline />
            <SnackbarProvider
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              maxSnack={3}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <HelmetProvider context={helmetContext}>
                  <BrowserRouter>
                    <Router />
                  </BrowserRouter>
                </HelmetProvider>
              </LocalizationProvider>
            </SnackbarProvider>
          </StyleSheetManager>
        </ThemeProvider>
      </I18nextProvider>
    </AppContext.Provider>
  );
}

export default App;
